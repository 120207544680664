<template>
  <App
    title="申请退款"
    left-text="取消"
    right-text="确认"
    @click-right="sure"
  >
    <div class="container">
      <RadioGroup v-model="model">
        <div class="group">
          <Radio
            v-for="($e, $i) in applyRefundReasonList"
            :key="$i"
            :name="$i"
          >{{$e}}</Radio>
          <!-- <Radio name="1">对产品不满意</Radio>
          <Radio name="2">对产品不满意</Radio>
          <Radio name="0">其他</Radio> -->
          <textarea
            placeholder="请填写其他原因"
            v-if="model === 9"
            v-model="otnerReason"
            maxlength="200"
          />
        </div>
      </RadioGroup>
    </div>
  </App>
</template>
<script>
import { RadioGroup, Radio, Toast } from 'vant'
import { refund } from '@/api/order'

export default {
  name: 'refound',
  components: {
    Radio,
    RadioGroup
  },
  data () {
    return {
      orderInfo: {},
      model: 0,
      applyRefundReasonList: [
        '等不及，购买其他品牌',
        '对产品不满意',
        '对新品牌不信任',
        '资金不足',
        '没有牌照或纸币哦啊',
        '担心异地售后无保障',
        '不能安装充电桩',
        '购买其他车型',
        '提车日期晚',
        '其他'
      ],
      otnerReason: ''
    }
  },
  methods: {
    async sure () {
      const params = {
        orderNum: this.orderInfo.orderNum,
        channel: this.$storage.get('channel')
      }
      if (this.model === 9) {
        if (!this.otnerReason) return Toast('请填写其他原因')
        else params.refundReason = this.otnerReason
      } else {
        params.refundReason = this.applyRefundReasonList[this.model]
      }
      const { code } = await refund(params)
      if (code !== '000000') return Toast('提交失败，请稍后重试')
      else this.$router.go(-1)
    }
  },
  created () {
    this.orderInfo = this.$route.query
  }
}
</script>
<style lang="less" scoped>
.container {
  background: #fff;
}
.group {
  position: relative;
  padding: 28px 24px 41px;

  /deep/ .van-radio {
    margin-bottom: 0.533333rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  textarea {
    width: calc(100% - 48px);
    height: 200px;
    padding: 24px 22px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  textarea::-webkit-input-placeholder {
    color: #ccc;
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    left: 24px;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #e6e6e6;
    transform: scale(1, 0.5);
  }
}
</style>